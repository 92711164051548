export default function useDate() {
  const dateRangeConjunction = ' ~ '
  const parseDateTable = timestamp => {
    if (timestamp) {
      const d = new Date(Number(timestamp))
      const year = d.getFullYear()
      const month = (`0${d.getMonth() + 1}`).slice(-2)
      const date = (`0${d.getDate()}`).slice(-2)
      return [year, month, date].join('/')
    }
    return '∞'
  }

  const parseDateDeep = timestamp => {
    const today = new Date()
    const d = new Date(Number(timestamp))
    if (today.getFullYear() === d.getFullYear()
      && today.getMonth() === d.getMonth()
      && today.getDate() === d.getDate()
    ) {
      const hour = (`0${d.getHours()}`).slice(-2)
      const min = (`0${d.getMinutes()}`).slice(-2)
      const sec = (`0${d.getSeconds()}`).slice(-2)
      return [hour, min, sec].join(':')
    }
    const year = d.getFullYear()
    const month = (`0${d.getMonth() + 1}`).slice(-2)
    const date = (`0${d.getDate()}`).slice(-2)
    return [year, month, date].join('/')
  }

  const parseDateToString = timestamp => {
    if (timestamp) {
      const value = new Date(Number(timestamp))
      const year = value.getFullYear()
      const month = value.getMonth() + 1
      const date = value.getDate()
      return [year, month > 9 ? month : `0${month}`, date > 9 ? date : `0${date}`].join('-')
    }
    return null
  }

  const todayString = () => {
    const today = new Date()
    const year = today.getFullYear()
    const month = today.getMonth() + 1
    const date = today.getDate()
    return [year, month > 9 ? month : `0${month}`, date > 9 ? date : `0${date}`].join('-')
  }

  const isClosed = timestamp => {
    if (timestamp) {
      const value = new Date()
      return value.getTime() > Number(timestamp)
    }
    return false
  }

  const getDateRangeFromToday = (numOfDate, conjunction) => {
    const join = conjunction ?? dateRangeConjunction
    const today = new Date()
    const startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - numOfDate)
    return parseDateToString(startDate.getTime()) + join + parseDateToString(today.getTime())
  }

  const parseDateRange = dateFilter => {
    if (dateFilter) {
      const start = Date.parse(dateFilter.split(dateRangeConjunction)[0])
      const end = Date.parse(dateFilter.split(dateRangeConjunction)[1])
      // eslint-disable-next-line no-restricted-globals
      return [isNaN(start) ? null : start, isNaN(end) ? null : end]
    }
    return [null, null]
  }

  return {
    dateRangeConjunction,
    parseDateTable,
    parseDateDeep,
    parseDateToString,
    todayString,
    isClosed,
    getDateRangeFromToday,
    parseDateRange,
  }
}
